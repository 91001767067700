<template>
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.5987 5.70501L15.762 2.66001C14.7587 2.21668 13.242 2.21668 12.2387 2.66001L5.40199 5.70501C3.67532 6.47501 3.41865 7.52502 3.41865 8.08502C3.41865 8.64501 3.67532 9.69502 5.40199 10.465L12.2387 13.51C12.7403 13.7317 13.3703 13.8483 14.0003 13.8483C14.6303 13.8483 15.2603 13.7317 15.762 13.51L22.5987 10.465C24.3253 9.69502 24.582 8.64501 24.582 8.08502C24.582 7.52502 24.337 6.47501 22.5987 5.70501ZM14.0004 19.8802C13.557 19.8802 13.1137 19.7868 12.7054 19.6118L4.84203 16.1118C3.64036 15.5752 2.70703 14.1402 2.70703 12.8218C2.70703 12.3435 3.09203 11.9585 3.57036 11.9585C4.0487 11.9585 4.4337 12.3435 4.4337 12.8218C4.4337 13.4518 4.9587 14.2685 5.54203 14.5252L13.4054 18.0252C13.7787 18.1885 14.2104 18.1885 14.5837 18.0252L22.447 14.5252C23.0304 14.2685 23.5554 13.4635 23.5554 12.8218C23.5554 12.3435 23.9404 11.9585 24.4187 11.9585C24.897 11.9585 25.282 12.3435 25.282 12.8218C25.282 14.1285 24.3487 15.5752 23.147 16.1118L15.2837 19.6118C14.887 19.7868 14.4437 19.8802 14.0004 19.8802ZM12.7054 25.3985C13.1137 25.5735 13.557 25.6668 14.0004 25.6668C14.4437 25.6668 14.887 25.5735 15.2837 25.3985L23.147 21.8985C24.442 21.3151 25.282 20.0318 25.282 18.6085C25.282 18.1301 24.897 17.7451 24.4187 17.7451C23.9404 17.7451 23.5554 18.1301 23.5554 18.6085C23.5554 19.3434 23.112 20.0201 22.447 20.3118L14.5837 23.8118C14.2104 23.9751 13.7787 23.9751 13.4054 23.8118L5.54203 20.3118C4.86536 20.0085 4.4337 19.3434 4.4337 18.6085C4.4337 18.1301 4.0487 17.7451 3.57036 17.7451C3.09203 17.7451 2.70703 18.1301 2.70703 18.6085C2.70703 20.0318 3.54703 21.3268 4.84203 21.8985L12.7054 25.3985Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'StacksIcon',
    };
</script>

<template>
    <svg viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.9819 16.5407C9.26284 16.8193 9.64268 16.9746 10.0381 16.9722C10.4324 16.9707 10.8102 16.8123 11.0879 16.5322C11.3658 16.2521 11.521 15.873 11.5193 15.4783C11.5178 15.0836 11.3594 14.706 11.0794 14.4279L7.67307 11.0209H17.4748C19.6005 11.0209 21.5647 12.1555 22.6274 13.9967C23.6904 15.8382 23.6904 18.107 22.6274 19.9482C21.5648 21.7897 19.6005 22.9243 17.4748 22.9243H13.0127C12.4812 22.9243 11.9901 23.2076 11.7245 23.668C11.4589 24.1283 11.4589 24.6956 11.7245 25.156C11.9901 25.6164 12.4812 25.9 13.0127 25.9H17.4748C20.6639 25.9 23.6099 24.1981 25.204 21.4359C26.7981 18.674 26.7981 15.2712 25.204 12.509C23.6095 9.74675 20.6632 8.04521 17.4745 8.04521H7.6773L11.0941 4.63767C11.4689 4.26036 11.6138 3.71163 11.4742 3.19824C11.3349 2.68516 10.9324 2.28517 10.4183 2.14939C9.9041 2.01361 9.35674 2.16235 8.9819 2.53995L3.12151 8.4021C2.99493 8.51031 2.88563 8.64055 2.79986 8.78923C2.70387 8.9556 2.64258 9.13593 2.61597 9.32015C2.60514 9.3926 2.59961 9.46617 2.59961 9.54032C2.59961 9.93353 2.75508 10.3105 3.03216 10.5892L8.9819 16.5407Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ReturnArrowIcon',
    };
</script>

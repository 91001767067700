<template>
    <div class="inline-select">
        <inline-select-option
            v-for="(option, index) in options"
            :key="index"
            :image="option.image"
            :label="option.label"
            :show-label="showLabel"
            :selected="index === selected"
            :size="size"
            :title="option.label"
            @click="$emit('input', index)"
        />
    </div>
</template>

<script>
    import InlineSelectOption from '@/components/inputs/InlineSelectOption';

    export default {
        name: 'InlineSelect',
        components: {
            InlineSelectOption,
        },
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            selected: {
                type: Number,
                default: 0,
            },
            size: {
                type: String,
                default: 'medium',
                validator: value => ['large', 'medium', 'small'].includes(value),
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .inline-select {
        display: flex;
        align-items: center;
        border-radius: 0.7rem;
        border: 1px solid #1a2030;
    }
</style>

<template>
    <label
        :class="{ checkbox: true, [`checkbox_${size}`]: true, checkbox_checked: checked, checkbox_disabled: disabled }"
        @click="checked = !checked"
    >
        <span>{{ label }}</span>
        <input type="checkbox" v-model="checked" v-on="$listeners" />
    </label>
</template>

<script>
    export default {
        name: 'Checkbox',
        props: {
            label: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: 'medium',
                validator: value => ['large', 'medium', 'small'].includes(value),
            },
            value: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            value(value) {
                this.checked = value;
            },
        },
        data() {
            return {
                checked: this.value,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.8rem;
        background-color: #1b2131;
        cursor: pointer;
        transition: background-color 0.3s;

        span {
            color: #55667f;
            transition: color 0.3s;
        }

        input {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            background-color: #1e273a;
            appearance: none;
            cursor: pointer;
            transition: background-color 0.3s;

            &:before {
                width: 60%;
                content: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuNDMzIDAuMjI2MDczQzExLjg2MDUgMC41NzU4IDExLjkyMzUgMS4yMDU4MiAxMS41NzM3IDEuNjMzMjdMNC45NTA2IDkuNzI4MkwwLjk3OTA3MSA2LjU4NDA3QzAuNTQ2MDU0IDYuMjQxMjcgMC40NzI5MjMgNS42MTIzNCAwLjgxNTcyOCA1LjE3OTMzQzEuMTU4NTMgNC43NDYzMSAxLjc4NzQ2IDQuNjczMTggMi4yMjA0OCA1LjAxNTk4TDQuNjQ4OTUgNi45Mzg1MkwxMC4wMjU4IDAuMzY2NzkyQzEwLjM3NTUgLTAuMDYwNjUyNyAxMS4wMDU2IC0wLjEyMzY1NSAxMS40MzMgMC4yMjYwNzNaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo=');
                opacity: 0;
                transition: opacity 0.3s;
            }

            &:checked {
                background-color: #3760cb;

                &:before {
                    opacity: 1;
                }
            }
        }

        &:hover {
            background-color: #262c41;

            input:not(:checked) {
                background-color: #555d75;
            }
        }

        &_checked {
            span {
                color: #ccd1de;
            }
        }

        &_disabled {
            pointer-events: none;
        }

        &_medium {
            height: 4.6rem;
        }

        &_small {
            height: 3.6rem;
            padding: 0 0.9rem;

            input {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 0.4rem;
            }

            span {
                margin-right: 1rem;
            }
        }
    }

    @media screen and (min-width: 22.5em) {
        .checkbox_medium {
            height: 4.8rem;
        }
    }

    @media screen and (min-width: 120em) {
        .checkbox {
            &_medium {
                height: 5.2rem;
            }

            &_small {
                height: 4.4rem;
                padding: 1.2rem;

                input {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
</style>

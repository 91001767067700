<template>
    <svg v-if="rarityClass" :class="['skin-rarity', `skin-rarity_${appId}_${rarityClass}`]" viewBox="0 0 76 61" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.59825 12.9978L27.5823 2.73229C34.2962 -1.09969 42.8809 1.17452 46.7545 7.81121L54.6456 21.3251L0.548828 52.1934V28.4959C0.548369 25.3547 1.38441 22.2688 2.97292 19.5483C4.56143 16.8278 6.84645 14.5686 9.59825 12.9978Z"
            :fill="`url(#${gradientId}_1)`"
            fill-opacity="0.5"
        />

        <path
            d="M66.3966 48.4001L48.4251 58.6547C41.7094 62.4867 33.1225 60.212 29.2478 53.5748L21.5001 40.2132L75.4513 9.49992V32.9013C75.451 36.0429 74.6141 39.1291 73.0247 41.8497C71.4353 44.5703 69.1493 46.8295 66.3966 48.4001Z"
            :fill="`url(#${gradientId}_2)`"
            fill-opacity="0.5"
        />

        <defs>
            <linearGradient :id="`${gradientId}_1`" x1="4.11538" y1="52.3874" x2="81.9433" y2="3.47505" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#252832" stop-opacity="0.13" />
                <stop offset="1" />
            </linearGradient>

            <linearGradient :id="`${gradientId}_2`" x1="23.9304" y1="53.5218" x2="85.2722" y2="21.1638" gradientUnits="userSpaceOnUse">
                <stop offset="0" />
                <stop offset="1" stop-color="#252832" stop-opacity="0.13" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    import { nanoid } from 'nanoid';

    export default {
        name: 'SkinRarity',
        props: {
            appId: {
                type: [String, Number],
                required: true,
            },
            rarity: {
                type: String,
                required: true,
            },
        },
        computed: {
            gradientId: () => `gradient_${nanoid()}`,
            rarityClass() {
                return 'EMPTY_RARITY' === this.rarity ? null : this.rarity.replace(' ', '-').toLowerCase();
            },
        },
    };
</script>

<!--suppress CssInvalidHtmlTagReference -->
<style lang="scss" scoped>
    .skin-rarity {
        &_730 {
            &_common {
                linearGradient:first-child stop:last-child,
                linearGradient:last-child stop:first-child {
                    stop-color: #cdcdcd;
                }
            }

            &_uncommon {
                linearGradient:first-child stop:last-child,
                linearGradient:last-child stop:first-child {
                    stop-color: #3aa3de;
                }
            }

            &_rare {
                linearGradient:first-child stop:last-child,
                linearGradient:last-child stop:first-child {
                    stop-color: #3555c0;
                }
            }

            &_mythical {
                linearGradient:first-child stop:last-child,
                linearGradient:last-child stop:first-child {
                    stop-color: #653ade;
                }
            }

            &_legendary {
                linearGradient:first-child stop:last-child,
                linearGradient:last-child stop:first-child {
                    stop-color: #de3ad7;
                }
            }

            &_ancient {
                linearGradient:first-child stop:last-child,
                linearGradient:last-child stop:first-child {
                    stop-color: #dd3d3d;
                }
            }

            &_immortal {
                linearGradient:first-child stop:last-child,
                linearGradient:last-child stop:first-child {
                    stop-color: #de623a;
                }
            }
        }
    }
</style>

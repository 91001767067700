<template>
    <div :class="['app-toast', `app-toast_${type}`]">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('close-toast')">
            <path
                d="M7.99964 15.7149L15.6292 8.08542L16.6597 9.11595L9.03016 16.7455L7.99964 15.7149ZM7.99964 9.10993L9.02414 8.08543L16.6597 15.721L15.6352 16.7455L7.99964 9.10993Z"
            />
        </svg>

        <h1 v-if="title">{{ title }}</h1>

        <p>{{ text }}</p>
    </div>
</template>

<script>
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'Toast',
        props: {
            text: {
                type: String,
                required: true,
            },
            title: String,
            type: {
                type: String,
                required: true,
                validator: value => Object.values(TYPE).includes(value),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .app-toast {
        position: relative;
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        padding: 1.6rem 3.2rem 1.6rem 2rem;
        border-radius: 0.8rem;
        background-color: #0e1527;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 0.4rem;
            height: 100%;
            transition: background-color 0.3s;
        }

        h1 {
            color: #c5cce3;
            font-family: 'Gotham Medium', sans-serif;
            font-size: 1.5rem;
            font-weight: 350;
            line-height: 1.2rem;
        }

        p {
            color: #787e96;
            font-size: 1.2rem;
            font-weight: 325;
            line-height: 1.4;
        }

        svg {
            position: absolute;
            top: 0.4rem;
            right: 0.4rem;
            width: 2.4rem;
            height: 2.4rem;
            fill: #a1acbd;
            cursor: pointer;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.75;
            }
        }

        &_error:before {
            background-color: #fe6a6a;
        }

        &_success:before {
            background-color: #4ab990;
        }
    }
</style>

import echo from './index';

const channelName = orderId => `${process.env.VUE_APP_ECHO_SERVER_CHANNEL_PREFIX}order.${orderId}`;

export const orderChannel = orderId => echo.channel(channelName(orderId));

export const leaveOrderChannel = orderId => echo.leave(channelName(orderId));

export const orderChannelEvents = {
    TradeOfferStatusChangedEvent: 'TradeOfferStatusChangedEvent',
};

<template>
    <div :class="{ skin: true, skin_active: isActive, skin_selected: isSelected }" v-on="$listeners">
        <p v-if="hasStatTrak" :class="{ 'skin__stat-trak': true, [`skin__stat-trak_${skin.steam_app_id.id}_${rarity}`]: rarity }" title="StatTrak™">
            ★
        </p>

        <p class="skin__price">{{ price }}</p>

        <div class="skin__image">
            <skin-rarity v-if="rarity" :app-id="skin.steam_app_id.id" :rarity="rarity" />

            <img :src="`https://community.akamai.steamstatic.com/economy/image/${skin.image}`" :alt="skin.market_hash_name" />
        </div>

        <p v-if="type" class="skin__type">{{ type }}</p>

        <p class="skin__title">{{ title }}</p>

        <p v-if="wear" class="skin__wear">{{ wear }}</p>
    </div>
</template>

<script>
    import { formatPrice } from '@/helpers/common';
    import { AVAILABLE_CURRENCIES } from '@/services/skinsPaymentApi/constants/currency';
    import SkinRarity from '@/components/pay/SkinRarity';

    const STAT_TRAK_PREFIX = 'StatTrak™';

    export default {
        name: 'Skin',
        components: {
            SkinRarity,
        },
        props: {
            currency: {
                type: String,
                required: true,
                validator: value => AVAILABLE_CURRENCIES.includes(value),
            },
            skin: {
                type: Object,
                required: true,
            },
            isActive: {
                type: Boolean,
                default: false,
            },
            isSelected: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            hasStatTrak() {
                return this.skin.market_hash_name.includes(STAT_TRAK_PREFIX);
            },
            price() {
                return formatPrice(this.skin.price[this.currency], this.currency);
            },
            rarity() {
                return 'EMPTY_RARITY' === this.skin.rarity ? null : this.skin.rarity;
            },
            type() {
                const titleParts = this.skin.market_hash_name.split('|');
                return titleParts.length > 1 ? titleParts[0].replace(STAT_TRAK_PREFIX, '').trim() : null;
            },
            title() {
                const titleParts = this.skin.market_hash_name.split('|');
                return titleParts.length > 1 ? titleParts[1].replace(/\(.+\)$/gi, '').trim() : this.skin.market_hash_name;
            },
            wear() {
                const titleParts = this.skin.market_hash_name.split('|');

                switch (titleParts.length) {
                    case 2:
                        const wear = titleParts[1].trim().match(/(?<=\().+(?=\))/i);
                        return wear ? wear[0] : null;
                    case 3:
                        return titleParts[2].trim();
                    default:
                        return null;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .skin {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1.5rem 0;
        border-radius: 1rem;
        background-color: #131825;
        overflow: hidden;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover:not(&_selected),
        &_active,
        &_selected {
            background-color: #161d2d;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            width: 100%;
            height: 100%;
            background: rgba(24, 31, 48, 0.6);
            z-index: 3;
        }

        &:after {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy43OTE1IDEuMDMyNTNDMTQuMzI1OCAxLjQ2OTY5IDE0LjQwNDYgMi4yNTcyMiAxMy45Njc0IDIuNzkxNTJMNS42ODg1IDEyLjkxMDJMMC43MjQwODMgOC45ODAwM0MwLjE4MjgxMyA4LjU1MTUzIDAuMDkxMzk4NiA3Ljc2NTM3IDAuNTE5OTA1IDcuMjI0MUMwLjk0ODQxMSA2LjY4MjgzIDEuNzM0NTcgNi41OTE0MSAyLjI3NTg0IDcuMDE5OTJMNS4zMTE0MyA5LjQyMzA5TDEyLjAzMjUgMS4yMDg0M0MxMi40Njk3IDAuNjc0MTIzIDEzLjI1NzIgMC41OTUzNyAxMy43OTE1IDEuMDMyNTNaIiBmaWxsPSIjNERCMjZBIi8+Cjwvc3ZnPgo=');
            position: absolute;
            top: 50%;
            left: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 1rem;
            background: rgba(77, 178, 106, 0.08);
            transform: translate(-50%, -50%);
            z-index: 4;
        }

        &_active:after {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi44ODM5IDIuODgzODhDMTMuMzcyIDIuMzk1NzMgMTMuMzcyIDEuNjA0MjcgMTIuODgzOSAxLjExNjEyQzEyLjM5NTcgMC42Mjc5NjEgMTEuNjA0MyAwLjYyNzk2MSAxMS4xMTYxIDEuMTE2MTJMNyA1LjIzMjIzTDIuODgzODggMS4xMTYxMkMyLjM5NTczIDAuNjI3OTYxIDEuNjA0MjcgMC42Mjc5NjEgMS4xMTYxMiAxLjExNjEyQzAuNjI3OTYxIDEuNjA0MjcgMC42Mjc5NjEgMi4zOTU3MyAxLjExNjEyIDIuODgzODhMNS4yMzIyMyA3TDEuMTE2MTIgMTEuMTE2MUMwLjYyNzk2MSAxMS42MDQzIDAuNjI3OTYxIDEyLjM5NTcgMS4xMTYxMiAxMi44ODM5QzEuNjA0MjcgMTMuMzcyIDIuMzk1NzMgMTMuMzcyIDIuODgzODggMTIuODgzOUw3IDguNzY3NzdMMTEuMTE2MSAxMi44ODM5QzExLjYwNDMgMTMuMzcyIDEyLjM5NTcgMTMuMzcyIDEyLjg4MzkgMTIuODgzOUMxMy4zNzIgMTIuMzk1NyAxMy4zNzIgMTEuNjA0MyAxMi44ODM5IDExLjExNjFMOC43Njc3NyA3TDEyLjg4MzkgMi44ODM4OFoiIGZpbGw9IiNGQTY2NjYiLz4KPC9zdmc+Cg==');
            background: rgba(250, 102, 102, 0.08);
        }

        &_selected,
        &_active:hover {
            &:after,
            &:before {
                display: flex;
            }
        }

        &__price,
        &__stat-trak {
            position: absolute;
            z-index: 2;
        }

        &__price {
            top: 1rem;
            right: 1rem;
            color: #5dd880;
            font-family: 'Gotham Bold', sans-serif;
            font-size: 1rem;
            font-weight: 400;
        }

        &__stat-trak {
            top: 0.8rem;
            left: 1rem;
            color: rgba(255, 255, 255, 0.3);
            font-size: 1.4rem;

            &_730 {
                &_common {
                    color: #cdcdcd;
                }

                &_uncommon {
                    color: #3aa3de;
                }

                &_rare {
                    color: #3555c0;
                }

                &_mythical {
                    color: #653ade;
                }

                &_legendary {
                    color: #de3ad7;
                }

                &_ancient {
                    color: #dd3d3d;
                }

                &_immortal {
                    color: #de623a;
                }
            }
        }

        &__image {
            position: relative;
            width: 85%;
            margin-bottom: 0.3rem;

            img {
                position: relative;
                max-width: 100%;
                z-index: 1;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 70%;
                transform: translate(-50%, -50%);
            }
        }

        &__wear,
        &__type {
            width: 85%;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1rem;
            font-weight: 325;
            text-align: center;
        }

        &__wear {
            margin-top: 0.3rem;
            color: rgba(255, 255, 255, 0.3);
        }

        &__type {
            margin-bottom: 0.3rem;
            color: #afb3c0;
        }

        &__title {
            width: 85%;
            color: #ffffff;
            font-size: 1.1rem;
            font-weight: 350;
            text-align: center;
        }
    }

    @media screen and (min-width: 120em) {
        .skin {
            padding: 2rem 0;

            &__price {
                top: 1.5rem;
                right: 1.5rem;
                font-size: 1.2rem;
            }

            &__stat-trak {
                top: 1.2rem;
                left: 1.5rem;
                font-size: 1.6rem;
            }

            &__image {
                margin-bottom: 0.6rem;
            }

            &__type,
            &__wear {
                font-size: 1.3rem;
            }

            &__wear {
                margin-top: 0.6rem;
            }

            &__type {
                margin-bottom: 0.6rem;
            }

            &__title {
                font-size: 1.4rem;
            }
        }
    }
</style>

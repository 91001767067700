<template>
    <svg viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.1345 2.09998H7.85276C4.95817 2.09998 2.59961 4.45618 2.59961 7.34788V15.0829V15.3209C2.59961 18.2245 4.95817 20.5688 7.85276 20.5688H12.4269C13.0821 20.5688 13.6181 21.1043 13.6181 21.7588V22.9131C13.6181 23.5676 13.0821 24.1031 12.4269 24.1031H9.54425C9.05587 24.1031 8.65086 24.5077 8.65086 24.9956C8.65086 25.4835 9.04395 25.9 9.54425 25.9H19.4907C19.9791 25.9 20.3841 25.4954 20.3841 25.0075C20.3841 24.5196 19.9791 24.115 19.4907 24.115H16.608C15.9529 24.115 15.4168 23.5795 15.4168 22.925V21.7707C15.4168 21.1162 15.9529 20.5807 16.608 20.5807H21.1465C24.053 20.5807 26.3996 18.2245 26.3996 15.3328V15.0948V7.35978C26.3877 4.45618 24.0291 2.09998 21.1345 2.09998Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'DesktopIcon',
    };
</script>

<template>
    <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.75 7.75C15.75 9 14.75 10.05 13.45 10.05C12.2 10.05 11.15 9.05 11.15 7.75C11.15 6.5 12.15 5.45 13.45 5.45C14.7 5.45 15.75 6.5 15.75 7.75ZM11.75 7.75C11.75 8.7 12.5 9.45 13.45 9.45C14.4 9.45 15.15 8.7 15.15 7.75C15.15 6.8 14.4 6.05 13.45 6.05C12.5 6.05 11.75 6.8 11.75 7.75ZM8.2 12.15L7.05 11.65C7.5 11.5 8 11.5 8.45 11.65C8.95 11.85 9.3 12.2 9.5 12.7C9.7 13.2 9.7 13.7 9.5 14.2C9.1 15.2 7.95 15.65 7 15.25C6.55 15.05 6.2 14.7 6 14.3L7.1 14.75C7.8 15.05 8.65 14.7 8.95 14C9.25 13.3 8.9 12.45 8.2 12.15ZM10.5 19C6.45 19 3 16.3 1.85 12.65L5.25 14.05C5.4 14.8 5.95 15.5 6.75 15.8C8 16.35 9.5 15.75 10 14.45C10.15 14.1 10.2 13.75 10.2 13.4L13.35 11.15C13.4 11.15 13.4 11.15 13.45 11.15C15.35 11.15 16.85 9.6 16.85 7.7C16.85 5.8 15.3 4.3 13.45 4.3C11.55 4.3 10.05 5.85 10.05 7.7V7.75L7.8 11C7.45 11 7.1 11.05 6.75 11.2C6.6 11.25 6.45 11.35 6.35 11.4L1.5 9.4C1.8 4.7 5.7 1 10.5 1C15.5 1 19.5 5.05 19.5 10C19.5 14.95 15.45 19 10.5 19Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'SteamIcon',
    };
</script>

<template>
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.0133 21.2267L20.48 5.86675C19.3333 3.80008 17.7466 2.66675 16 2.66675C14.2533 2.66675 12.6666 3.80008 11.52 5.86675L2.98664 21.2267C1.90664 23.1867 1.78664 25.0667 2.6533 26.5467C3.51997 28.0267 5.22664 28.8401 7.46664 28.8401H24.5333C26.7733 28.8401 28.48 28.0267 29.3466 26.5467C30.2133 25.0667 30.0933 23.1734 29.0133 21.2267ZM15 12.0001C15 11.4534 15.4533 11.0001 16 11.0001C16.5466 11.0001 17 11.4534 17 12.0001V18.6667C17 19.2134 16.5466 19.6667 16 19.6667C15.4533 19.6667 15 19.2134 15 18.6667V12.0001ZM16.9466 23.6134C16.88 23.6667 16.8133 23.7201 16.7466 23.7734C16.6666 23.8267 16.5866 23.8667 16.5066 23.8934C16.4266 23.9334 16.3466 23.9601 16.2533 23.9734C16.1733 23.9867 16.08 24.0001 16 24.0001C15.92 24.0001 15.8266 23.9867 15.7333 23.9734C15.6533 23.9601 15.5733 23.9334 15.4933 23.8934C15.4133 23.8667 15.3333 23.8267 15.2533 23.7734C15.1866 23.7201 15.12 23.6667 15.0533 23.6134C14.8133 23.3601 14.6666 23.0134 14.6666 22.6667C14.6666 22.3201 14.8133 21.9734 15.0533 21.7201C15.12 21.6667 15.1866 21.6134 15.2533 21.5601C15.3333 21.5067 15.4133 21.4667 15.4933 21.4401C15.5733 21.4001 15.6533 21.3734 15.7333 21.3601C15.9066 21.3201 16.0933 21.3201 16.2533 21.3601C16.3466 21.3734 16.4266 21.4001 16.5066 21.4401C16.5866 21.4667 16.6666 21.5067 16.7466 21.5601C16.8133 21.6134 16.88 21.6667 16.9466 21.7201C17.1866 21.9734 17.3333 22.3201 17.3333 22.6667C17.3333 23.0134 17.1866 23.3601 16.9466 23.6134Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'DangerIcon',
    };
</script>

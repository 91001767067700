<template>
    <div class="payment-order-info">
        <div>
            <logo-link class="payment-order-info__logo" />

            <div>
                <h1>{{ $t('pay.info.title') }}</h1>

                <p>
                    {{ order.website.toUpperCase() }}
                    <span>{{ $t('pay.info.order_id') }}:</span>
                    {{ order.id }}
                </p>
            </div>
        </div>

        <div class="payment-order-info__actions">
            <locale-select-not-localized class="payment-order-info__locale-select" />

            <text-button v-if="showButton" size="small medium-lg" @click.stop="$emit('openModal')">
                {{ $t('pay.info.button') }}
                <template #icon>
                    <trade-icon />
                </template>
            </text-button>
        </div>
    </div>
</template>

<script>
    import LogoLink from '@/components/LogoLink';
    import TextButton from '@/components/buttons/TextButton';
    import TradeIcon from '@/components/icons/TradeIcon';
    import LocaleSelectNotLocalized from '@/components/inputs/LocaleSelectNotLocalized';

    export default {
        name: 'PaymentOrderInfo',
        components: {
            LocaleSelectNotLocalized,
            LogoLink,
            TextButton,
            TradeIcon,
        },
        props: {
            order: {
                type: Object,
                required: true,
            },
            showButton: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .payment-order-info {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        > div:first-child {
            display: flex;
            align-items: center;
        }

        h1,
        p,
        span {
            font-family: 'Gotham Book', sans-serif;
            font-weight: 325;
        }

        h1 {
            color: #e5ecf9;
            font-size: 2.2rem;
            margin-bottom: 0.3rem;
        }

        p {
            color: #a6acc8;
            font-size: 1.2rem;
            line-height: 1.2;
        }

        span {
            margin-left: 0.5rem;
            color: #55667f;
            font-family: 'Gotham Book', sans-serif;
            font-weight: 325;
        }
    }

    .payment-order-info__logo {
        display: none;
        margin-right: 1rem;
    }

    .payment-order-info__actions {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        margin-top: 1rem;
    }

    .payment-order-info__locale-select {
        margin-left: 0.5rem;
    }

    @media screen and (min-width: 30em) {
        .payment-order-info {
            flex-direction: row;
            align-items: center;
        }

        .payment-order-info__actions {
            flex-direction: row;
            justify-content: flex-start;
            margin: 0 0 0 auto;
        }

        .payment-order-info__locale-select {
            margin: 0 0.5rem 0 0;
        }
    }

    @media screen and (min-width: 75em) {
        .payment-order-info__logo {
            display: block;
        }
    }

    @media screen and (min-width: 120em) {
        .payment-order-info {
            h1 {
                font-size: 2.8rem;
            }

            p {
                margin-top: 0.5rem;
                font-size: 1.4rem;
            }
        }

        .payment-order-info__logo {
            margin-right: 2.5rem;
        }
    }
</style>

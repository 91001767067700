<template>
    <div
        :class="{ 'inline-select-option': true, [`inline-select-option_${size}`]: true, 'inline-select-option_selected': selected }"
        v-on="$listeners"
    >
        <img v-if="showImage" :src="image" :alt="label" />
        <p v-if="showLabel">{{ label }}</p>
    </div>
</template>

<script>
    export default {
        name: 'InlineSelectOption',
        props: {
            image: String,
            label: {
                type: String,
                required: true,
            },
            selected: {
                type: Boolean,
                default: false,
            },
            showImage: {
                type: Boolean,
                default: true,
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
            size: {
                type: String,
                default: 'medium',
                validator: value => ['large', 'medium', 'small'].includes(value),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .inline-select-option {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: background-color 0.3s, opacity 0.3s;

        img {
            width: 2rem;
            height: 2rem;
        }

        &_selected {
            background-color: #1a2030;
            opacity: 1;
            pointer-events: none;
        }

        &_small {
            width: 3.4rem;
            height: 3.4rem;
        }

        &:first-child {
            border-radius: 0.7rem 0 0 0.7rem;
        }

        &:last-child {
            border-radius: 0 0.7rem 0.7rem 0;
        }

        &:not(:last-child) {
            border-right: 1px solid #1a2030;
        }

        &:hover {
            opacity: 1;
        }
    }

    @media screen and (min-width: 120em) {
        .inline-select-option_small {
            width: 4.2rem;
            height: 4.2rem;
        }
    }
</style>

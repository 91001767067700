<template>
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.0013 2.6665C8.65464 2.6665 2.66797 8.65317 2.66797 15.9998C2.66797 23.3465 8.65464 29.3332 16.0013 29.3332C23.348 29.3332 29.3346 23.3465 29.3346 15.9998C29.3346 8.65317 23.348 2.6665 16.0013 2.6665ZM22.3746 12.9332L14.8146 20.4932C14.628 20.6798 14.3746 20.7865 14.108 20.7865C13.8413 20.7865 13.588 20.6798 13.4013 20.4932L9.62797 16.7198C9.2413 16.3332 9.2413 15.6932 9.62797 15.3065C10.0146 14.9198 10.6546 14.9198 11.0413 15.3065L14.108 18.3732L20.9613 11.5198C21.348 11.1332 21.988 11.1332 22.3746 11.5198C22.7613 11.9065 22.7613 12.5332 22.3746 12.9332Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'CheckCircleIcon',
    };
</script>

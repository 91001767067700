import { TYPE } from 'vue-toastification';
import Toast from '@/components/Toast';

export default {
    methods: {
        notificationText(response) {
            switch (response.status) {
                case 500:
                    return this.$t('general.unknown_error');
                case 429:
                    return this.$t('general.too_many_requests');
                default:
                    return response.data.message;
            }
        },
        processErrorResponse(response, observer) {
            if (422 === response.status) {
                if (observer) {
                    observer.setErrors(response.data.errors);
                }

                return;
            }

            this.$toast({
                component: Toast,
                props: {
                    text: this.notificationText(response),
                    type: TYPE.ERROR,
                },
            });

            if (observer) {
                observer.reset();
            }
        },
    },
};

<template>
    <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.5 1C8.745 1 7.107 1.504 5.73 2.368L11.904 8.551L11.913 6.085C11.913 5.716 12.219 5.41 12.588 5.41C12.966 5.41 13.263 5.716 13.263 6.085L13.254 10.18C13.254 10.459 13.083 10.702 12.831 10.801C12.75 10.837 12.66 10.855 12.579 10.855C12.399 10.855 12.228 10.783 12.102 10.657L4.731 3.277C4.695 3.241 4.668 3.205 4.641 3.169C2.715 4.825 1.5 7.273 1.5 10C1.5 14.968 5.532 19 10.5 19C12.255 19 13.893 18.496 15.27 17.632L9.096 11.449L9.087 13.915C9.087 14.284 8.781 14.59 8.412 14.59C8.034 14.59 7.737 14.284 7.737 13.915L7.746 9.82C7.746 9.541 7.917 9.298 8.169 9.199C8.412 9.091 8.709 9.145 8.898 9.343L16.269 16.723C16.305 16.759 16.332 16.795 16.359 16.831C18.285 15.175 19.5 12.727 19.5 10C19.5 5.032 15.468 1 10.5 1Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'TradeIcon',
    };
</script>

<template>
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.45967 8.35477C3.14416 8.6335 3.11137 9.11863 3.38643 9.43835L7.42852 14.1367C7.57246 14.304 7.78078 14.4 7.9998 14.4C8.21883 14.4 8.42715 14.304 8.57108 14.1367L12.6132 9.43835C12.8882 9.11864 12.8554 8.6335 12.5399 8.35477C12.2244 8.07605 11.7457 8.10928 11.4706 8.42899L8.7577 11.5824L8.7577 2.36802C8.7577 1.94387 8.41838 1.60003 7.99981 1.60003C7.58123 1.60002 7.24191 1.94387 7.24191 2.36802L7.24191 11.5824L4.52899 8.42899C4.25393 8.10927 3.77518 8.07605 3.45967 8.35477Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ArrowDownIcon',
    };
</script>

<template>
    <scroll-container>
        <div class="skins">
            <slot />
        </div>
    </scroll-container>
</template>

<script>
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'Skins',
        components: {
            ScrollContainer,
        },
    };
</script>

<style lang="scss" scoped>
    .skins {
        display: grid;
        grid-auto-rows: 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    }

    .scroll-container {
        width: calc(100% + 0.65rem);
        padding-right: 0.65rem;
    }

    @media screen and (min-width: 30em) {
        .scroll-container {
            width: calc(100% + 0.9rem);
            padding-right: 0.9rem;
        }
    }

    @media screen and (min-width: 120em) {
        .skins {
            grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        }

        .scroll-container {
            width: calc(100% + 1.65rem);
            padding-right: 1.65rem;
        }
    }
</style>
